<script setup lang="ts">
import Layout3 from '~/components/layouts/Layout3.vue'
import SiteHeader from '~/components/SiteHeader.vue'
import useTracking from '~/composables/useTracking'

const { loadWelcomeTrackers } = useTracking()
loadWelcomeTrackers()
</script>

<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header />

    <layout-3
      name="signup-success"
      :title="$t('signup.complete.title')"
      :subtitle="$t('signup.complete.text')"
      :image="{ src: 'crossing-the-finish-line', width: 535, height: 494 }"
    >
      <v-btn class="mx-0" nuxt :to="{ path: '/' }" color="info" x-large>
        {{ $t('signup.complete.cta.primary') }}
      </v-btn>
    </layout-3>
  </v-row>
</template>
